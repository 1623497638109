import { useEffect, useMemo, useState } from 'react';
import { MenuItem, Select } from '@mui/material';
import { Box } from '@mui/system';
import CookieService from 'services/cookie.service';

const PlaceSection = () => {
  var cookieService = useMemo(() => new CookieService(), []);
  const [place, setPlace] = useState('SP');

  const handleChange = (event) => {
    cookieService.setCookie('burgerx-place', event.target.value);
    cookieService.deleteCookie('filterId');
    window.location.reload();
  };

  useEffect(() => {
    let savedPlace = cookieService.getCookie('burgerx-place');

    if (savedPlace) {
      setPlace(savedPlace);
    }
  }, []);

  return (
    <>
      <Box>
        <Select labelId="place-select-label" id="place-select" value={place} onChange={handleChange}>
          <MenuItem value="SP">São Paulo</MenuItem>
          <MenuItem value="BH">Belo Horizonte</MenuItem>
        </Select>
      </Box>
    </>
  );
};

export default PlaceSection;
