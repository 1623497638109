import Cookies from 'universal-cookie';

class CookieService {
  constructor() {
    this.cookie = new Cookies();
  }

  getCookie(key) {
    try {
      return this.cookie.get(key);
    } catch (error) {
      console.error('Erro ao obter marcas da API:', error);
      throw error;
    }
  }

  setCookie(key, value, path = { path: '/' }) {
    try {
      this.cookie.set(key, value, path);
    } catch (error) {
      console.error('Erro ao obter marcas da API:', error);
      throw error;
    }
  }

  deleteCookie(key){
    try {
      this.cookie.remove(key);
    } catch (error) {
      console.error('Erro ao obter marcas da API:', error);
      throw error;
    }
  }
}

export default CookieService;
